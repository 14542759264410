import { useContext } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ExportPageData } from 'components/ExportPageData';
import { FilterTags } from 'components/FilterTags';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useUtils from 'hooks/useUtils';
import useListPage from 'hooks/useListPage';

const VpatrimonioListPage = (props) => {
	const utils = useUtils();
	const {empid, setempid} = useApp();
	VpatrimonioListPage.defaultProps.fieldName = "empid";
	VpatrimonioListPage.defaultProps.fieldValue = {empid};

	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, apiUrl, sortBy, sortOrder, apiRequestError, getPageBreadCrumbs, onSort, pagination } = pageController;
	const { } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } = pagination;
	// return sum of qtde for the current records
	const totalQtde = utils.sum(records, 'qtde');
	// return sum of f_patr for the current records
	const totalFPatr = utils.sum(records, 'f_patr');
	function VendaTemplate(data) {
		if (data) {
			return (
				<>{utils.currency(data.venda, 'BR', 2)}</>
			);
		}
	}
	function FPatrTemplate(data) {
		if (data) {
			return (
				<>{utils.currency(data.f_patr, 'BR', 2)}</>
			);
		}
	}

	function Totalizadores({ totalQtde, totalFPatr }) {
		return (
			<div className="container">
				<div className="flex">
					<div>Total de Estoque : <Chip style={{ marginLeft: '15px' }} label={totalQtde} /></div>
					<div>Total de Patrimonio R$ : <Chip style={{ marginLeft: '15px' }} label={totalFPatr} /></div>
				</div>

				<style>{`
		.container {
			position: relative;
		}

		.flex {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			gap: 8px; /* Adiciona um espaçamento entre as divs */
			padding: 16px; /* Adiciona um padding interno */
			border-radius: 4px; /* Adiciona bordas arredondadas (opcional) */
			align-items: flex-end;
			background-color: #23238E; /* Adiciona um fundo (opcional) */
		}

		.flex > div {
			display: flex;
			align-items: center;
		}

`}</style>
			</div>
		);
	}

	function PageLoading() {
		if (loading) {
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{ width: '50px', height: '50px' }} /> </div>
						<div className="font-bold text-lg">Carregando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage() {
		if (pageReady && !records.length) {
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Nenhum Registro Encontrado
				</div>
			);
		}
	}
	function ExportData() {
		if (props.exportData && records.length) {
			const downloadFileName = `${utils.dateNow()}-vpatrimonio`;
			return (
				<div className="m-2">
					<ExportPageData csv={false} pageUrl={apiUrl} downloadFileName={downloadFileName} butonLabel="Exportar" tooltip="Exportar" buttonIcon="pi pi-print" />
				</div>
			);
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
			const pagerReportTemplate = {
				layout: pagination.layout,
				CurrentPageReport: (options) => {
					return (
						<>
							<span className="text-sm text-gray-500 px-2">Registros <b>{recordsPosition} do {options.totalRecords}</b></span>
						</>
					);
				}
			}
			return (
				<div className="flex-grow-1">
					<Paginator first={firstRow} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} template={pagerReportTemplate} />
				</div>
			)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<ExportData />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs() {
		if (props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if (apiRequestError) {
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
		<main id="VpatrimonioListPage" className="main-page">
			{(props.showHeader) &&
				<section className="page-section mb-3" >
					<div className="container-fluid">
						<div className="grid justify-content-between align-items-center">
							<div className="col " >
								<Title title="Patrimonio de Empresas" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
							</div>
						</div>
					</div>
				</section>
			}
			<section className="page-section " >
				<div className="container-fluid">
					<div className="grid ">
						<div className="col comp-grid" >
							<FilterTags filterController={filterController} />
							<div >
								<PageBreadcrumbs />
								<div className="page-records">
									<DataTable
										// Nao Funcionou - > paginator={true} rows={5} rowsPerPageOptions={[5, 10]}
										lazy={true}
										loading={loading}
										value={records}
										dataKey="empid"
										sortField={sortBy}
										sortOrder={sortOrder}
										onSort={onSort}
										className=" p-datatable-sm"
										stripedRows={true}
										showGridlines={false}
										rowHover={true}
										responsiveLayout="stack"
										emptyMessage={<EmptyRecordMessage />}
									>
										{/*PageComponentStart*/}
										<Column headerStyle={{ width: '15%' }} field="razaosocial" header="Empresa" sortable={true} ></Column>
										<Column headerStyle={{ width: '5%' }} field="codexterno" header="Cod.Ext" align={"center"} sortable={true} ></Column>
										<Column headerStyle={{ width: '50%' }} field="nome" header="Nome" sortable={true} ></Column>
										<Column headerStyle={{ width: '10%' }} field="qtde" header="Qtde" align={"right"} sortable={true} ></Column>
										<Column headerStyle={{ width: '10%' }} field="venda" header="Venda" align={"right"} body={VendaTemplate} sortable={true} ></Column>
										<Column headerStyle={{ width: '15%' }} field="f_patr" header="Patrimonio" align={"right"} body={FPatrTemplate} sortable={true} ></Column>
										{/*PageComponentEnd*/}
									</DataTable>
								</div>
								<PageFooter />
							</div>
							{/* <Totalizadores totalQtde={totalQtde} totalFPatr={totalFPatr} /> */}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
VpatrimonioListPage.defaultProps = {
	primaryKey: '',
	pageName: 'vpatrimonio',
	apiPath: 'vpatrimonio/index',
	routeName: 'vpatrimoniolist',
	msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
	msgTitle: "Apagar registro",
	msgAfterDelete: "Registro excluído com sucesso",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: true,
	importData: false,
	keepRecords: false,
	multiCheckbox: false,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default VpatrimonioListPage;
