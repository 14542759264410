import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApi from 'hooks/useApi';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
import MasterDetailPages from './MasterDetailPages';
const PedidosEditPage = (props) => {
    const app = useApp();
    const api = useApi();
    // form validation schema
    const validationSchema = yup.object().shape({
        tipo: yup.string().required().label("Tipo"),
        empid: yup.string().required().label("Empresa"),
        dt: yup.string().required().label("Data/Hora"),
        funcid: yup.string().required().label("Funcionário"),
        // status: yup.string().nullable().label("Status")
    });
    // form default values
    const formDefaultValues = {
        tipo: '',
        empid: '',
        dt: new Date(),
        funcid: '',
        //status: '',
    }
    //where page logics resides
    const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
    //destructure and grab what we need
    const { formData, currentRecord, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
    //Event raised on form submit success
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if (props.redirect) {
            app.navigate(`/pedidos`);
        }
    }
    // loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }
    const OnClickConfirm = async () => {
        try {
            var response = await app.dialog.Confirm("Autorizar o Pedido", "Apos este processo, será dado baixa no estoque e o pedido será AUTORIZADO");
            if (response == true) {
                let response = await api.post(`pedidos/updatestate/${formData.id}/F`);
            }
        }
        catch (error) {
        }
    }
    const OnClickCancel = async () => {
        try {
            var response = await app.dialog.Confirm("Cancelar o Pedido", "Apos este processo, o pedido atual será cancelado, e qualquer baixa, será EXTORNADA no estoque");
            {
                let response = await api.post(`pedidos/updatestate/${formData.id}/C`);
            }
        }
        catch (error) {
        }
    }
    
    //display error page 
    if (apiRequestError) {
        return (
            <PageRequestError error={apiRequestError} />
        );
    }
    //page is ready when formdata loaded successfully
    if (pageReady) {
        return (
            <main id="PedidosEditPage" className="main-page">
                {(props.showHeader) &&
                    <section className="page-section mb-3" >
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage &&
                                    <div className="col-fixed " >
                                        <Button onClick={() => app.navigate(-1)} label="" className="p-button p-button-text " icon="pi pi-arrow-left" />
                                    </div>
                                }
                                <div className="col " >
                                    <Title title="Editar Pedido" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="page-section " >
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid" >
                                <div >
                                    <div className="grid ">
                                        <div className="col">
                                            <Formik
                                                initialValues={formData}
                                                validationSchema={validationSchema}
                                                onSubmit={(values, actions) => {
                                                    submitForm(values);
                                                }
                                                }
                                            >
                                                {(formik) => {
                                                    return (
                                                        <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                                            <div className="grid">
                                                                <div className="md:col-4 col-12">
                                                                    Tipo *
                                                                    <div>
                                                                        <Dropdown name="tipo" optionLabel="label" optionValue="value" value={formik.values.tipo} onChange={formik.handleChange} options={app.menus.tipo2} label="Tipo" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.tipo)} />
                                                                        <ErrorMessage name="tipo" component="span" className="p-error" />
                                                                    </div>
                                                                </div>
                                                                <div className="md:col-8 col-12">
                                                                    Empresa *
                                                                    <div>
                                                                        <DataSource apiPath="components_data/empid_option_list"  >
                                                                            {
                                                                                ({ response }) =>
                                                                                    <>
                                                                                        <Dropdown name="empid" optionLabel="label" optionValue="value" value={formik.values.empid} onChange={formik.handleChange} options={response} label="Empresa" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.empid)} />
                                                                                        <ErrorMessage name="empid" component="span" className="p-error" />
                                                                                    </>
                                                                            }
                                                                        </DataSource>
                                                                    </div>
                                                                </div>
                                                                <div className="md:col-4 col-12">
                                                                    Data/Hora *
                                                                    <div>
                                                                        <Calendar name="dt" value={formik.values.dt} onChange={formik.handleChange} showButtonBar showTime dateFormat="yy-mm-dd" hourFormat="24" showIcon className={inputClassName(formik?.errors?.dt)} />
                                                                        <ErrorMessage name="dt" component="span" className="p-error" />
                                                                    </div>
                                                                </div>
                                                                <div className="md:col-8 col-12">
                                                                    Funcionário *
                                                                    <div>
                                                                        <DataSource apiPath="components_data/funcid_option_list"  >
                                                                            {
                                                                                ({ response }) =>
                                                                                    <>
                                                                                        <Dropdown name="funcid" optionLabel="label" optionValue="value" value={formik.values.funcid} onChange={formik.handleChange} options={response} label="Funcionário" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.funcid)} />
                                                                                        <ErrorMessage name="funcid" component="span" className="p-error" />
                                                                                    </>
                                                                            }
                                                                        </DataSource>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {props.showFooter &&
                                                                <div className="text-center my-3">
                                                                    <Button onClick={(e) => handleSubmit(e, formik)} type="submit" label="Atualizar" icon="pi pi-send" loading={saving} />
                                                                </div>
                                                            }
                                                        </Form>
                                                    );
                                                }
                                                }
                                            </Formik>
                                        </div>
                                        {
                                            (currentRecord && !props.isSubPage) &&
                                            <div className="col-12">
                                                <div className="card my-3">
                                                    <MasterDetailPages masterRecord={currentRecord} scrollIntoView={false} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-fixed comp-grid" >
                                <div className="">
                                    {(formData.status == "A") &&
                                        (
                                            <div className="">
                                                <Button
                                                    label="Confirmar Pedido"
                                                    icon="pi pi-bolt"
                                                    type="button"
                                                    className="p-button w-full bg-primary-color "
                                                    onClick={OnClickConfirm}
                                                />
                                            </div>
                                        )
                                    }
                                    {(formData.status != "C") &&
                                        (
                                            <div className="">
                                                <Button
                                                    label="Cancelar Pedido Pedido"
                                                    icon="pi pi-bolt"
                                                    type="button"
                                                    className="p-button w-full bg-primary-color "
                                                    onClick={OnClickCancel}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
PedidosEditPage.defaultProps = {
    primaryKey: 'id',
    pageName: 'pedidos',
    apiPath: 'pedidos/edit',
    routeName: 'pedidosedit',
    submitButtonLabel: "Atualizar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Atualizar registro",
    msgAfterSave: "Registro atualizado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false
}
export default PedidosEditPage;
