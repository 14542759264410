import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { CheckDuplicate } from 'components/CheckDuplicate';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const UsersAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Name"),
		password: yup.string().required().label("Password"),
		confirm_password: yup.string().required().label("Confirm Password").oneOf([yup.ref('password')], "Your passwords do not match"),
		email: yup.string().email().required().label("Email"),
		phone: yup.string().required().label("Phone"),
		profile_photo: yup.string().nullable().label("Profile Photo")
	});
	
	//form default values
	const formDefaultValues = {
		name: '', 
		password: '', 
		confirm_password: '', 
		email: '', 
		phone: '', 
		profile_photo: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/users`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="UsersAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Adicionar novo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        Name *
                                        <div> 
                                            <CheckDuplicate value={formik.values.name} apiPath="components_data/users_name_exist">
                                            { (checker) => 
                                            <>
                                            <InputText name="name" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.name}   label="Name" type="text" placeholder="Entrar Name"        className={inputClassName(formik?.errors?.name)} />
                                            <ErrorMessage name="name" component="span" className="p-error" />
                                            {(!checker.loading && checker.exist) && <small className="p-error">Não disponível</small>}
                                            {checker.loading && <small className="text-500">Checking...</small> }
                                            </>
                                            }
                                            </CheckDuplicate>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        Password *
                                        <div> 
                                            <Password name="password" value={formik.values.password} onChange={formik.handleChange} label="Password" placeholder="Entrar Password"  inputClassName="w-full" toggleMask feedback className={inputClassName(formik?.errors?.password)} />
                                            <ErrorMessage name="password" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        Confirm Password *
                                        <div> 
                                            <Password name="confirm_password" id="confirm_password" className={inputClassName(formik?.errors?.comfirm_password)} inputClassName="w-full" feedback={false} toggleMask  value={formik.values.confirm_password} onChange={formik.handleChange} label="Confirme a Senha" placeholder="Confirme a Senha"  />
                                            <ErrorMessage name="confirm_password" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        Email *
                                        <div> 
                                            <CheckDuplicate value={formik.values.email} apiPath="components_data/users_email_exist">
                                            { (checker) => 
                                            <>
                                            <InputText name="email" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="Entrar Email"        className={inputClassName(formik?.errors?.email)} />
                                            <ErrorMessage name="email" component="span" className="p-error" />
                                            {(!checker.loading && checker.exist) && <small className="p-error">Não disponível</small>}
                                            {checker.loading && <small className="text-500">Checking...</small> }
                                            </>
                                            }
                                            </CheckDuplicate>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        Phone *
                                        <div> 
                                            <InputText name="phone"  onChange={formik.handleChange}  value={formik.values.phone}   label="Phone" type="text" placeholder="Entrar Phone"        className={inputClassName(formik?.errors?.phone)} />
                                            <ErrorMessage name="phone" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        Profile Photo 
                                        <div> 
                                            <div className={inputClassName(formik?.errors?.profile_photo)}>
                                                <Uploader name="profile_photo" showUploadedFiles value={formik.values.profile_photo} uploadPath="fileuploader/upload/profile_photo" onChange={(paths) => formik.setFieldValue('profile_photo', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Escolha arquivos ou solte-os aqui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                            </div>
                                            <ErrorMessage name="profile_photo" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Enviar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
UsersAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'users',
	apiPath: 'users/add',
	routeName: 'usersadd',
	submitButtonLabel: "Enviar",
	formValidationError: "O formulário é inválido",
	formValidationMsg: "Por favor, preencha o formulário",
	msgTitle: "Criar registro",
	msgAfterSave: "Registro adicionado com sucesso",
	msgBeforeSave: "Deseja Salvar os Dados Agora?",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default UsersAddPage;
