import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const EstoqueEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		empid: yup.string().required().label("Empresa"),
		prodid: yup.string().required().label("Produto"),
		qtde: yup.number().required().label("Estoque"),
		qtdeanterior: yup.number().required().label("Qtde Anterior"),
		dtultimomov: yup.string().required().label("Ultimo Movimento"),
		active: yup.string().nullable().label("Status")
	});
	// form default values
	const formDefaultValues = {
		empid: '', 
		prodid: '', 
		qtde: '', 
		qtdeanterior: '', 
		dtultimomov: new Date(), 
		active: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/estoque`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="EstoqueEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar Estoque"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        Produto *
                                        <div> 
                                            <DataSource   apiPath="components_data/prodid_option_list"  >
                                                {
                                                ({ response }) => 
                                                <>
                                                <Dropdown  name="prodid"     optionLabel="label" optionValue="value" value={formik.values.prodid} onChange={formik.handleChange} options={response} label="Produto"  placeholder="Selecione um valor ..."  className={inputClassName(formik?.errors?.prodid)}   />
                                                <ErrorMessage name="prodid" component="span" className="p-error" />
                                                </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                    <div className="md:col-3 col-12">
                                        Estoque *
                                        <div> 
                                            <InputText name="qtde"  onChange={formik.handleChange}  value={formik.values.qtde}   label="Estoque" type="number" placeholder="Entrar Estoque"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.qtde)} />
                                            <ErrorMessage name="qtde" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-3 col-12">
                                        Qtde Anterior *
                                        <div> 
                                            <InputText name="qtdeanterior"  onChange={formik.handleChange}  value={formik.values.qtdeanterior}   label="Qtde Anterior" type="number" placeholder="Ultimo Movimento" hint="Estoque, antes da ultima Entrada/Saida" min={0}  step={0.1}  readonly  className={inputClassName(formik?.errors?.qtdeanterior)} />
                                            <ErrorMessage name="qtdeanterior" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-3 col-12">
                                        Ultimo Movimento *
                                        <div> 
                                            <Calendar name="dtultimomov" value={formik.values.dtultimomov} onChange={formik.handleChange} showButtonBar showTime dateFormat="yy-mm-dd" hourFormat="24"showIcon className={inputClassName(formik?.errors?.dtultimomov)}      readonly  />
                                            <ErrorMessage name="dtultimomov" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-3 col-12">
                                        Status 
                                        <div> 
                                            <div className="flex flex-wrap">
                                                {
                                                app.menus.active2.map((option) => {
                                                return (
                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                    <RadioButton inputId={option.value} name="active" value={option.value} onChange={formik.handleChange}  checked={formik.values.active === option.value} className={inputClassName(formik?.errors?.active, '')} />
                                                    <label htmlFor={option.value}>{option.label}</label>
                                                </div>
                                                )
                                                })
                                                }
                                            </div>
                                            <ErrorMessage name="active" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Atualizar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
EstoqueEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'estoque',
	apiPath: 'estoque/edit',
	routeName: 'estoqueedit',
	submitButtonLabel: "Atualizar",
	formValidationError: "O formulário é inválido",
	formValidationMsg: "Por favor, preencha o formulário",
	msgTitle: "Atualizar registro",
	msgAfterSave: "Registro atualizado com sucesso",
	msgBeforeSave: "Deseja Salvar os Dados Agora?",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default EstoqueEditPage;
