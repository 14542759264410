import { useContext, useEffect } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { CanView } from 'components/Can';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ExportPageData } from 'components/ExportPageData';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import FuncionariosAddPage from 'pages/funcionarios/Add';
import useApp from 'hooks/useApp';
import useUtils from 'hooks/useUtils';
import useListPage from 'hooks/useListPage';
import FuncionariosEditPage from './Edit';

const FuncionariosListPage = (props) => {
	const app = useApp();
	const utils = useUtils();
	const {empid, setempid} = useApp();

	FuncionariosListPage.defaultProps.fieldName = "empid";
	FuncionariosListPage.defaultProps.fieldValue= 1;

    useEffect(() => {
		FuncionariosListPage.defaultProps.fieldValue= empid;
    }, [empid]);

	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, apiUrl, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } = pagination;
	
	function ActionButton(data) {
		const items = [
			{
				label: "Editar",
				command: (event) => { app.openPageDialog(<FuncionariosEditPage isSubPage apiPath={`/funcionarios/edit/${data.id}`} />, { closeBtn: true }) },
				icon: "pi pi-pencil"
			},
			{
				label: "Delete",
				command: (event) => { deleteItem(data.id) },
				icon: "pi pi-trash"
			}
		]
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function ActiveTemplate(data) {
		if (data) {
			return (
				<>{app.FmtStatusChip({ value: data.active })}</>
			);
		}
	}

	const OnClickPrintEPI = (param) => {
		if (param) {
			var paramns = { id: 1, filter: `ficha_epi|f_funcid|${param.id}|0|0` };
			app.openPageDialog(<app.PrintPreview paramns={paramns} />, { maximized: true, closeBtn: true, width: '80vw' })
		}
	};

	function TemplatePrintFichaEPI(data) {
		if (data) {
			return (
				<app.PrintButton onClick={() => OnClickPrintEPI(data)} />
			);
		}
	}

	const OnClickPrintFicha = (param) => {
		if (param) {
			var paramns = { id: 3, filter: `funcionarios|id|${param.id}|0|0` };
			app.openPageDialog(<app.PrintPreview paramns={paramns} />, { maximized: true, closeBtn: true, width: '80vw' })
		}
	};

	function TemplatePrintFicha(data) {
		if (data) {
			return (
				<app.PrintButton onClick={() => OnClickPrintFicha(data)} />
			);
		}
	}

	function PageLoading() {
		if (loading) {
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{ width: '50px', height: '50px' }} /> </div>
						<div className="font-bold text-lg">Carregando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage() {
		if (pageReady && !records.length) {
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Nenhum Registro Encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Excluir selecionado" />
				</div>
			)
		}
	}
	function ExportData() {
		if (props.exportData && records.length) {
			const downloadFileName = `${utils.dateNow()}-funcionarios`;
			return (
				<div className="m-2">
					<ExportPageData csv={false} pageUrl={apiUrl} downloadFileName={downloadFileName} butonLabel="Exportar" tooltip="Exportar" buttonIcon="pi pi-print" />
				</div>
			);
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
			const pagerReportTemplate = {
				layout: pagination.layout,
				CurrentPageReport: (options) => {
					return (
						<>
							<span className="text-sm text-gray-500 px-2">Registros <b>{recordsPosition} do {options.totalRecords}</b></span>
						</>
					);
				}
			}
			return (
				<div className="flex-grow-1">
					<Paginator first={firstRow} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} template={pagerReportTemplate} />
				</div>
			)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
				<ExportData />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs() {
		if (props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if (apiRequestError) {
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
		<main id="FuncionariosListPage" className="main-page">
			{(props.showHeader) &&
				<section className="page-section mb-3" >
					<div className="container-fluid">
						<div className="grid justify-content-between align-items-center">
							<div className="col " >
								<Title title="Funcionarios" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
							</div>
							<div className="col-fixed " >
								<CanView pagePath="funcionarios/add">
									<Button label="Adicionar novo" icon="pi pi-plus" onClick={() => app.openPageDialog(<FuncionariosAddPage isSubPage apiPath={`/funcionarios/add`} />, { closeBtn: true })} className="p-button w-full bg-primary " />
								</CanView>
							</div>
							<div className="col-12 md:col-3 " >
								<span className="p-input-icon-left w-full">
									<i className="pi pi-search" />
									<InputText placeholder="Pesquisa" className="w-full" value={filters.search.value} onChange={(e) => setFilterValue('search', e.target.value)} />
								</span>
							</div>
						</div>
					</div>
				</section>
			}
			<section className="page-section " >
				<div className="container-fluid">
					<div className="grid ">
						<div className="col comp-grid" >
							<FilterTags filterController={filterController} />
							<div >
								<PageBreadcrumbs />
								<div className="page-records">
									<DataTable
										lazy={true}
										loading={loading}
										value={records}
										dataKey="id"
										sortField={sortBy}
										sortOrder={sortOrder}
										onSort={onSort}
										className=" p-datatable-sm"
										stripedRows={true}
										showGridlines={false}
										rowHover={true}
										responsiveLayout="stack"
										emptyMessage={<EmptyRecordMessage />}
									>
										{/*PageComponentStart*/}
										<Column  headerStyle={{ width: '2rem' }}></Column>
										<Column headerStyle={{ width: '5%' }} field="id" header="Id" sortable={true} ></Column>
										<Column headerStyle={{ width: '5%' }} body={TemplatePrintFicha} field="ID" header="Ficha" align={"center"} ></Column>
										<Column headerStyle={{ width: '5%' }} body={TemplatePrintFichaEPI} field="ID" header="EPI" ></Column>
										<Column headerStyle={{ width: '50%' }} field="nome" header="Nome" sortable={true} ></Column>
										<Column headerStyle={{ width: '50%' }} field="apelido" header="Apelido" sortable={true} ></Column>
										<Column field="matricula" header="Matricula" sortable={true} align={"center"} ></Column>
										<Column field="cpf" header="CPF" sortable={true} align={"center"} ></Column>
										<Column field="active" header="Active" body={ActiveTemplate} sortable={true} ></Column>
										<Column headerStyle={{ width: '2rem' }} headerClass="text-center" body={ActionButton}></Column>
										{/*PageComponentEnd*/}
									</DataTable>
								</div>
								<PageFooter />
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
FuncionariosListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'funcionarios',
	apiPath: 'funcionarios/index',
	routeName: 'funcionarioslist',
	msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
	msgTitle: "Apagar registro",
	msgAfterDelete: "Registro excluído com sucesso",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: true,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: "",
	fieldValue: "",
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default FuncionariosListPage;
