import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const EmpresasAddPage = (props) => {
    const app = useApp();

    //form validation rules
    const validationSchema = yup.object().shape({
        tipo: yup.string().required().label("Tipo"),
        razaosocial: yup.string().required().label("Razao Social"),
        nomefantasia: yup.string().required().label("Nome Fantasia"),
        cnpj: yup.string().min(14).min(14).required().label("CNPJ"),
        cep: yup.string().min(8).min(8).nullable().label("CEP"),
        logradouro: yup.string().required().label("Logradouro"),
        munid: yup.string().required().label("Municipio"),
        fone: yup.string().nullable().label("Fone"),
        mail: yup.string().required().label("Mail"),
        username: yup.string().nullable().label("Username"),
        password: yup.string().nullable().label("Password"),
        apuradiainicio: yup.number().min(1).max(31).required().label("Dia Inicial da Apuracao"),
        apuradiafinal: yup.number().min(1).max(31).required().label("Dia Final da Apuração"),
        prodcad: yup.string().required().label("Cadastra Produtos?"),
        prodintegra: yup.string().required().label("Integração?"),
        active: yup.string().required().label("Status")
    });

    //form default values
    const formDefaultValues = {
        tipo: '',
        razaosocial: '',
        nomefantasia: '',
        cnpj: '',
        cep: '',
        logradouro: '',
        munid: '',
        fone: '',
        mail: '',
        username: '',
        password: '',
        apuradiainicio: "1",
        apuradiafinal: "15",
        prodcad: '',
        prodintegra: '',
        active: '',
    }

    //page hook where logics resides
    const pageController = useAddPage({ props, formDefaultValues, afterSubmit });

    // destructure and grab what the page needs
    const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;

    //event raised after form submit
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        resetForm();
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if (props.redirect) {
            app.navigate(`/empresas`);
        }
    }

    // page loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }

    //page has loaded any required data and ready to render
    if (pageReady) {
        return (
            <main id="EmpresasAddPage" className="main-page">
                {(props.showHeader) &&
                    <section className="page-section mb-3" >
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage &&
                                    <div className="col-fixed " >
                                        <Button onClick={() => app.navigate(-1)} label="" className="p-button p-button-text " icon="pi pi-arrow-left" />
                                    </div>
                                }
                                <div className="col " >
                                    <Title title="Adicionar novo" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="page-section " >
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid" >
                                <div >
                                    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) => submitForm(values)}>
                                        {(formik) =>
                                            <>
                                                <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                                    <div className="grid">
                                                        <div className="md:col-2 col-12">
                                                            Tipo *
                                                            <div>
                                                                <Dropdown name="tipo" optionLabel="label" optionValue="value" value={formik.values.tipo} onChange={formik.handleChange} options={app.menus.tipo} label="Tipo" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.tipo)} />
                                                                <ErrorMessage name="tipo" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-10 col-12">
                                                            Razao Social *
                                                            <div>
                                                                <InputText name="razaosocial" onChange={formik.handleChange} value={formik.values.razaosocial} label="Razao Social" type="text" placeholder="Entrar Razao Social" className={inputClassName(formik?.errors?.razaosocial)} />
                                                                <ErrorMessage name="razaosocial" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-8 col-12">
                                                            Nome Fantasia *
                                                            <div>
                                                                <InputText name="nomefantasia" onChange={formik.handleChange} value={formik.values.nomefantasia} label="Nome Fantasia" type="text" placeholder="Entrar Nome Fantasia" className={inputClassName(formik?.errors?.nomefantasia)} />
                                                                <ErrorMessage name="nomefantasia" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            CNPJ *
                                                            <div>
                                                                <InputText name="cnpj" onChange={formik.handleChange} value={formik.values.cnpj} label="CNPJ" type="text" placeholder="Entrar CNPJ" className={inputClassName(formik?.errors?.cnpj)} />
                                                                <ErrorMessage name="cnpj" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            CEP
                                                            <div>
                                                                <InputText name="cep" onChange={formik.handleChange} value={formik.values.cep} label="CEP" type="text" placeholder="Entrar CEP" className={inputClassName(formik?.errors?.cep)} />
                                                                <ErrorMessage name="cep" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-8 col-12">
                                                            Logradouro *
                                                            <div>
                                                                <InputText name="logradouro" onChange={formik.handleChange} value={formik.values.logradouro} label="Logradouro" type="text" placeholder="Entrar Logradouro" className={inputClassName(formik?.errors?.logradouro)} />
                                                                <ErrorMessage name="logradouro" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            Municipio *
                                                            <div>
                                                                <DataSource apiPath="components_data/munid_option_list"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown name="munid" optionLabel="label" optionValue="value" value={formik.values.munid} onChange={formik.handleChange} options={response} label="Municipio" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.munid)} />
                                                                                <ErrorMessage name="munid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Fone
                                                            <div>
                                                                <InputText name="fone" onChange={formik.handleChange} value={formik.values.fone} label="Fone" type="text" placeholder="Entrar Fone" className={inputClassName(formik?.errors?.fone)} />
                                                                <ErrorMessage name="fone" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-8 col-12">
                                                            Mail *
                                                            <div>
                                                                <InputText name="mail" onChange={formik.handleChange} value={formik.values.mail} label="Mail" type="text" placeholder="Entrar Mail" className={inputClassName(formik?.errors?.mail)} />
                                                                <ErrorMessage name="mail" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            Username
                                                            <div>
                                                                <InputText name="username" onChange={formik.handleChange} value={formik.values.username} label="Username" type="text" placeholder="Entrar Username" className={inputClassName(formik?.errors?.username)} />
                                                                <ErrorMessage name="username" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            Password
                                                            <div>
                                                                <Password name="password" value={formik.values.password} onChange={formik.handleChange} label="Password" placeholder="Entrar Password" inputClassName="w-full" toggleMask feedback className={inputClassName(formik?.errors?.password)} />
                                                                <ErrorMessage name="password" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            Dia Inicial da Apuracao *
                                                            <div>
                                                                <InputText name="apuradiainicio" onChange={formik.handleChange} value={formik.values.apuradiainicio} label="Dia Inicial da Apuracao" type="number" placeholder="Entrar Dia Inicial da Apuracao" min={1} max={31} step={1} className={inputClassName(formik?.errors?.apuradiainicio)} />
                                                                <ErrorMessage name="apuradiainicio" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            Dia Final da Apuração *
                                                            <div>
                                                                <InputText name="apuradiafinal" onChange={formik.handleChange} value={formik.values.apuradiafinal} label="Dia Final da Apuração" type="number" placeholder="Entrar Dia Final da Apuração" min={1} max={31} step={1} className={inputClassName(formik?.errors?.apuradiafinal)} />
                                                                <ErrorMessage name="apuradiafinal" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Cadastra Produtos? *
                                                            <div>
                                                                <div className="flex flex-wrap">
                                                                    {
                                                                        app.menus.prodcad.map((option) => {
                                                                            return (
                                                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                                                    <RadioButton inputId={option.value} name="prodcad" value={option.value} onChange={formik.handleChange} checked={formik.values.prodcad === option.value} className={inputClassName(formik?.errors?.prodcad, '')} />
                                                                                    <label htmlFor={option.value}>{option.label}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <ErrorMessage name="prodcad" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Integração? *
                                                            <div>
                                                                <div className="flex flex-wrap">
                                                                    {
                                                                        app.menus.prodcad.map((option) => {
                                                                            return (
                                                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                                                    <RadioButton inputId={option.value} name="prodintegra" value={option.value} onChange={formik.handleChange} checked={formik.values.prodintegra === option.value} className={inputClassName(formik?.errors?.prodintegra, '')} />
                                                                                    <label htmlFor={option.value}>{option.label}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <ErrorMessage name="prodintegra" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Status *
                                                            <div>
                                                                <div className="flex flex-wrap">
                                                                    {
                                                                        app.menus.active.map((option) => {
                                                                            return (
                                                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                                                    <RadioButton inputId={option.value} name="active" value={option.value} onChange={formik.handleChange} checked={formik.values.active === option.value} className={inputClassName(formik?.errors?.active, '')} />
                                                                                    <label htmlFor={option.value}>{option.label}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <ErrorMessage name="active" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.showFooter &&
                                                        <div className="text-center my-3">
                                                            <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Enviar" icon="pi pi-send" loading={saving} />
                                                        </div>
                                                    }
                                                </Form>
                                            </>
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

//page props and default values
EmpresasAddPage.defaultProps = {
    primaryKey: 'id',
    pageName: 'empresas',
    apiPath: 'empresas/add',
    routeName: 'empresasadd',
    submitButtonLabel: "Enviar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Criar registro",
    msgAfterSave: "Registro adicionado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false
}
export default EmpresasAddPage;
