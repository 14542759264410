import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ExportPageData } from 'components/ExportPageData';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import PedidoitensAddPage from 'pages/pedidoitens/Add';
import useApp from 'hooks/useApp';
import useUtils from 'hooks/useUtils';

import useListPage from 'hooks/useListPage';
const PedidoitensListPage = (props) => {
	const app = useApp();
	const utils = useUtils();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, apiUrl, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } = pagination;
	// return sum of qtde for the current records
	const totalQtde = utils.sum(records, 'qtde');
	function ActionButton(data) {
		const items = [
			{
				label: "Editar",
				command: (event) => { app.navigate(`/pedidoitens/edit/${data.id}`) },
				icon: "pi pi-pencil"
			},
			{
				label: "Delete",
				command: (event) => { deleteItem(data.id) },
				icon: "pi pi-trash"
			}
		]
		return props.IsActive ? (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />)
		: null;
	}
	function QtdeTemplate(data) {
		if (data) {
			return (
				<>{app.FmtQtdChip({ value: data.qtde })}</>
			);
		}
	}
	function UnitTemplate(data) {
		if (data) {
			return (
				<>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.unit)}</>
			);
		}
	}
	function PageLoading() {
		if (loading) {
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{ width: '50px', height: '50px' }} /> </div>
						<div className="font-bold text-lg">Carregando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage() {
		if (pageReady && !records.length) {
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Nenhum Registro Encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Excluir selecionado" />
				</div>
			)
		}
	}
	function ExportData() {
		if (props.exportData && records.length) {
			const downloadFileName = `${utils.dateNow()}-pedidoitens`;
			return (
				<div className="m-2">
					<ExportPageData csv={false} pageUrl={apiUrl} downloadFileName={downloadFileName} butonLabel="Exportar" tooltip="Exportar" buttonIcon="pi pi-print" />
				</div>
			);
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
			const pagerReportTemplate = {
				layout: pagination.layout,
				CurrentPageReport: (options) => {
					return (
						<>
							<span className="text-sm text-gray-500 px-2">Registros <b>{recordsPosition} do {options.totalRecords}</b></span>
						</>
					);
				}
			}
			return (
				<div className="flex-grow-1">
					<Paginator first={firstRow} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} template={pagerReportTemplate} />
				</div>
			)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
				<ExportData />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.IsActive && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs() {
		if (props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if (apiRequestError) {
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
		<main id="PedidoitensListPage" className="main-page">
			{(props.showHeader) &&
				<section className="page-section mb-3" >
					<div className="container-fluid">
						<div className="grid justify-content-between align-items-center">
							<div className="col " >
								<Title title="Pedido Itens" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
							</div>
							<div className="col-fixed " >
								<Button label="Adicionar novo" icon="pi pi-plus" onClick={() => app.openPageDialog(<PedidoitensAddPage isSubPage apiPath={`/pedidoitens/add`} />, { closeBtn: true, width: '70vw' })} className="p-button w-full bg-primary " />
							</div>
							<div className="col-12 md:col-3 " >
								<span className="p-input-icon-left w-full">
									<i className="pi pi-search" />
									<InputText placeholder="Pesquisa" className="w-full" value={filters.search.value} onChange={(e) => setFilterValue('search', e.target.value)} />
								</span>
							</div>
						</div>
					</div>
				</section>
			}
			<section className="page-section " >
				<div className="container-fluid">
					<div className="grid ">
						<div className="col comp-grid" >
							<FilterTags filterController={filterController} />
							<div >
								<PageBreadcrumbs />
								<div className="page-records">
									<DataTable
										lazy={true}
										loading={loading}
										selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
										value={records}
										dataKey="id"
										sortField={sortBy}
										sortOrder={sortOrder}
										onSort={onSort}
										className=" p-datatable-sm"
										stripedRows={true}
										showGridlines={false}
										rowHover={true}
										responsiveLayout="stack"
										emptyMessage={<EmptyRecordMessage />}
									>
										{/*PageComponentStart*/}
										{/* <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column> */}
										<Column field="pedid" header="Pedido" sortable={true} ></Column>
										<Column field="prodid" header="Produto" sortable={true} ></Column>
										<Column field="validate" header="Validade" sortable={true} ></Column>
										<Column field="qtde" header="Qtde" body={QtdeTemplate} sortable={true} ></Column>
										<Column field="unit" header="Unitario" body={UnitTemplate} sortable={true} ></Column>
										<Column headerStyle={{ width: '2rem' }} headerClass="text-center" body={ActionButton}></Column>
										{/*PageComponentEnd*/}
									</DataTable>
									<div className="flex justify-content-around font-bold p-3">
										<div>Quantidade Total : <Chip label={app.FmtQtdChip({ value: totalQtde })} /></div>
									</div>
								</div>
								<PageFooter />
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
PedidoitensListPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'pedidoitens',
	apiPath: 'pedidoitens/index',
	routeName: 'pedidoitenslist',
	msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
	msgTitle: "Apagar registro",
	msgAfterDelete: "Registro excluído com sucesso",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: true,
	importData: false,
	keepRecords: false,
	multiCheckbox: false,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
	IsActive:true

}
export default PedidoitensListPage;
